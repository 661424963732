import PropTypes from 'prop-types';
import React from 'react';
import { useInView } from 'react-intersection-observer';

function AnimatedText(props) {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5
  });
  return (
    <video autoPlay loop muted playsInline ref={ref} className={`animated-video-block ${inView ? 'in-view' : ''}`}>
      <source src={props.src} type="video/mp4" />
    </video>
  );
}

AnimatedText.propTypes = {
  src: PropTypes.array
};

export default AnimatedText;
