import React from 'react';

function EmailAlt() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
      <g data-name="Group 486" transform="translate(-1273 -5197)">
        <g
          fill="none"
          stroke="#f28e1e"
          strokeWidth="2"
          data-name="Ellipse 36"
          transform="translate(1273 5197)"
        >
          <circle cx="22" cy="22" r="22" stroke="none" />
          <circle cx="22" cy="22" r="21" />
        </g>
        <g data-name="Group 485" transform="translate(1186.925 5173.5)">
          <path
            d="M117.131 36.82H99.019A3.022 3.022 0 0096 39.839v11.32a3.022 3.022 0 003.019 3.019h18.112a3.022 3.022 0 003.019-3.019v-11.32a3.022 3.022 0 00-3.019-3.019zm1.509 14.339a1.511 1.511 0 01-1.509 1.509H99.019a1.511 1.511 0 01-1.509-1.509v-11.32a1.511 1.511 0 011.509-1.509h18.112a1.511 1.511 0 011.509 1.509z"
            data-name="Path 55"
          />
          <path
            d="M117.942 42.1a.755.755 0 00-1.062-.106l-7.824 6.4-7.824-6.4a.755.755 0 10-.955 1.168l8.3 6.792a.754.754 0 00.955 0l8.3-6.792a.755.755 0 00.11-1.062z"
            data-name="Path 56"
            transform="translate(-.981 -1.227)"
          />
          <path
            d="M119.288 50.041a.755.755 0 00-1.067 1.067l3.019 3.019a.755.755 0 001.067-1.067z"
            data-name="Path 57"
            transform="translate(-5.397 -3.189)"
          />
          <path
            d="M103.24 50.041l-3.019 3.019a.755.755 0 101.067 1.067l3.019-3.019a.755.755 0 00-1.067-1.067z"
            data-name="Path 58"
            transform="translate(-.981 -3.189)"
          />
        </g>
      </g>
    </svg>
  );
}

export default EmailAlt;
