import PropTypes from 'prop-types';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import EmailAlt from './elements/svg/email-alt';
import PhoneAlt from './elements/svg/phone-alt';

const CTA = ({ inline }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.25
  });
  return (
    <>
      <section
        className={`${inline ? 'cta cta--inline' : 'section cta'} ${inView ? 'in-view' : ''}`}
        ref={ref}
      >
        <div className={inline ? '' : 'site-wide'}>
          <p>Have questions? Need guidance? After that new big idea?</p>
          <p>
            <strong>Get in touch here:</strong>{' '}
            <a href="mailto:hello@headnorth.agency">
              <EmailAlt />
            </a>
          </p>
        </div>
      </section>
    </>
  );
};

CTA.propTypes = {
  inline: PropTypes.bool
};

export default CTA;
