import PropTypes from 'prop-types';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import PortableText from '../portableText';

function AnimatedText(props) {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5
  });
  return (
    <div ref={ref} className={`animated-text-block ${inView ? 'in-view' : ''}`}>
      <PortableText blocks={props.blocks} />
    </div>
  );
}

AnimatedText.propTypes = {
  blocks: PropTypes.array
};

export default AnimatedText;
