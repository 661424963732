import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { buildImageObj, getWorkUrl } from '../../lib/helpers';
import { imageUrlFor } from '../../lib/image-url';
import Button from '../global/elements/buttons/button';
import PortableText from '../portableText';

function WorkCardRelated(props) {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.80
  });
  return (
    <div className={`work-card ${inView ? 'in-view' : ''}`} ref={ref}>        
        <div className="image">
          <Link to={getWorkUrl(props.slug.current)}>
            <div className="work-card__image">
              {props.cardMedia.image && props.cardMedia.image.asset && (
                <img
                  src={imageUrlFor(buildImageObj(props.cardMedia.image)).auto('format').url()}
                  alt={props.cardMedia.alt}
                />
              )}

              {props.cardMedia.video && props.cardMedia.video.asset && (
                <video className="hero__media" autoPlay loop muted playsInline>
                  <source src={props.cardMedia.video.asset.url} type="video/mp4" />
                </video>
              )}
            </div>
          </Link>
        </div>
        <div className="copy">
          <div className="work-card__details">
              <h2>{props.title}</h2>
                <h3>{props.subtitle}</h3>
              <Button text="View full case study" link={getWorkUrl(props.slug.current)} />
          </div>
        </div>
    </div>
  );
}

WorkCardRelated.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  slug: PropTypes.object,
  cardMedia: PropTypes.object
};

export default WorkCardRelated;
