import PropTypes from 'prop-types';
import React from 'react';
import WorkCardRelated from './work-card-related';

function shuffleArray(cards, sector) {
  let filteredArray;
  let removeItems = [];

  cards.forEach((card, index) => {
    filteredArray = card.sector.filter((cardCat) => {
      return (
        sector.filter((postCat) => {
          return postCat.title === cardCat.title;
        }).length === 1
      );
    });

    if (filteredArray.length > 0) {
      removeItems.push(card);
    }
  });
  
  // cards = cards.filter(item => !removeItems.includes(item))

  removeItems.sort(() => Math.random() - 0.5);
  
  return removeItems;
}

function WorkListRelated(props) {
  const shuffledPosts = shuffleArray(props.nodes, props.sector);
  return (
    <>
      {shuffledPosts.length > 0 && (
        <section className="section bg-black reduce">
          <div className="site-wide grid-container grid-container--half grid-container--reverse">
            {shuffledPosts.slice(0, 2).map((node, index) => (
              <div key={index} className="grid-column work-cards work-cards--related">
                <WorkCardRelated {...node} />
              </div>
            ))}
          </div>
        </section>
      )}
    </>
  );
}

WorkListRelated.propTypes = {
  nodes: PropTypes.array,
  sector: PropTypes.array
};

export default WorkListRelated;
