import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Container from '../components/global/container';
import Header from '../components/global/header';
import SEO from '../components/global/seo';
import GraphQLErrorList from '../components/graphql-error-list';
import Work from '../components/work/work';
import WorkListRelated from '../components/work/work-list-related';
import Layout from '../containers/layout';
import { mapEdgesToNodes, toPlainText } from '../lib/helpers';

export const query = graphql`
  query WorkDetailTemplateQuery($id: String!) {
    post: sanityWork(id: { eq: $id }) {
      id
      title
      subtitle
      _rawIntro(resolveReferences: { maxDepth: 10 })
      _rawContent(resolveReferences: { maxDepth: 10 })
      sector {
        title
      }
      seo {
        title
        keywords
        description
        image {
          image {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
        }
      }
      cardMedia {
        alt
        image {
          hotspot {
            _key
            _type
            height
            width
            x
            y
          }
          asset {
            _id
          }
          crop {
            top
            right
            left
            bottom
            _type
            _key
          }
        }
        video {
          asset {
            _id
          }
        }
      }
      headerMedia {
        alt
        image {
          hotspot {
            _key
            _type
            height
            width
            x
            y
          }
          asset {
            _id
          }
          crop {
            top
            right
            left
            bottom
            _type
            _key
          }
        }
        video {
          asset {
            _id
            url
          }
        }
      }
      introMedia {
        alt
        image {
          hotspot {
            _key
            _type
            height
            width
            x
            y
          }
          asset {
            _id
          }
          crop {
            top
            right
            left
            bottom
            _type
            _key
          }
        }
        video {
          asset {
            _id
          }
        }
      }
    }
    work: allSanityWork(
      filter: { 
        slug: { 
          current: { 
            nin: [ "aviva-for-advisers", "aviva-volunteering-campaign", "cancer-enhancements-adviser-awareness-campaign" ]
          }
        }
      }
    ) {
      edges {
        node {
          id
          title
          subtitle
          slug {
            current
          }
          cardMedia {
            image {
              crop {
                _key
                _type
                top
                bottom
                left
                right
              }
              hotspot {
                _key
                _type
                x
                y
                height
                width
              }
              asset {
                _id
              }
            }
            video {
              asset {
                _id
                url
              }
            }
            alt
          }
          sector {
            title
          }
        }
      }
    }
  }
`;

const WorkDetailTemplate = (props) => {
  const { data, errors } = props;
  const post = data && data.post;
  const work = (data || {}).work ? mapEdgesToNodes(data.work) : [];
  const sectorArray = post.sector;

  work.forEach((workCard, i) => {
    // remove card of current work
    if (workCard.title === post.title) {
      work.splice(i, 1);
    }
  });
  
  return (
    <>
      <Header headerStyle="clear" />
      <Layout>
        {errors && <SEO title="GraphQL Error" />}
        {post.seo && (
          <SEO
            title={post.seo.title !== null ? post.seo.title : false}
            description={post.seo.description !== null ? post.seo.description : false}
            keywords={post.seo.keywords !== null ? post.seo.keywords : false}
            image={post.seo.image !== null ? post.seo.image : false}
          />
        )}

        {errors && (
          <Container>
            <GraphQLErrorList errors={errors} />
          </Container>
        )}

        {post && <Work {...post} />}

        {work && <WorkListRelated nodes={work} sector={sectorArray} />}
      </Layout>
    </>
  );
};

WorkDetailTemplate.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object
};

export default WorkDetailTemplate;
