import PropTypes from 'prop-types';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import Button from './elements/buttons/button';
import SocialIcons from './social-icons';

const GetInTouch = ({ colour, top, middle, bottom }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.25
  });
  return (
    <>
      <div
        ref={ref}
        className={`social-contact ${colour ? 'social-contact--white' : ''} ${
          top ? 'social-contact--top' : ''
        } ${middle ? 'social-contact--middle' : ''} ${bottom ? 'social-contact--bottom' : ''} ${
          inView ? 'in-view' : ''
        }`}
      >
        <SocialIcons />
        {colour ? (
          <Button text="Get in touch" link="/contact" white />
        ) : (
          <Button text="Get in touch" link="/contact" />
        )}
      </div>
    </>
  );
};

GetInTouch.propTypes = {
  colour: PropTypes.string,
  top: PropTypes.bool,
  middle: PropTypes.bool,
  bottom: PropTypes.bool
};

export default GetInTouch;
