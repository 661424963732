import PropTypes from 'prop-types';
import React from 'react';
import { useInView } from 'react-intersection-observer';

function AnimatedImage(props) {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5
  });
  return (
    <img
      src={props.src}
      alt={props.alt}
      ref={ref}
      className={`animated-image-block ${inView ? 'in-view' : ''}`}
    />
  );
}

AnimatedImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string
};

export default AnimatedImage;
